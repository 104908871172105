<template>
  <div class="liff-reservation-schedule">
    <div class="s-mb-3">請選擇您要預約的日期</div>
    <div class="liff-reservation-schedule__date s-mb-4">
      <Datepicker 
        placeholder="請輸入查詢開始時間"
        format="yyyy/MM/dd"
        v-model="date"
        :language="zh"
        :disabled-dates="disabledDates"
        calendar-class="datepicker-calendar"
        wrapper-class="datepicker-wrapper"
        bootstrap-styling
        inline
        >
      </Datepicker>
    </div>

    <div class="s-mb-3">請選擇預約時間</div>
    <div class="liff-reservation-schedule__timer">
      <div 
        v-for="timeOption in timeOptions" 
        :key="timeOption" 
        class="liff-reservation-schedule__time-option"
        :class="{ 'selected': time === timeOption }"
        @click="time = timeOption"
      >
        <input 
          type="radio"  
          :id="timeOption" 
          :value="timeOption" 
          v-model="time" 
          class="liff-reservation-schedule__time-input" 
          />
        <label :for="timeOption">{{ timeOption }}</label>
      </div>
    </div>

    <div class="liff-reservation-schedule__notice">
      <div class="liff-reservation-schedule__notice-divider"></div>
      <div class="s-mb-3 s-font-medium s-pt-3">注意事項</div>
      <div class="s-text-sm s-text-gray">週一為固定店休日，望周知。如果注意事項比較常會折行注意事項文字。</div>
    </div>

    <div v-if="date && time" class="liff-reservation-schedule__confirm">
      <div class="s-flex s-justify-between s-items-center s-mb-3">
        <div class="s-font-medium">已選：<span class="s-text-primary">{{ selectedDateTime }}</span></div>
        <div class="s-text-primary cursor-pointer s-font-medium s-flex-shrink-0" @click="date = ''; time = '';">重選</div>
      </div>
      <div class="s-flex s-justify-between s-items-center" style="gap: 8px;">
        <button
          class="s-btn s-liff-theme-btn-outline s-btn-sm"
          type="button"
          @click="prevStep"
        >
        上一步
        </button>
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          @click="nextStep">
        下一步
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { zh } from "vuejs-datepicker/dist/locale";
import {
  format,
  subDays,
} from "date-fns";
  
export default {
    components: {
      Datepicker
    },
    data () {
      return {
        zh,
        date: '',
        time: null,
        disabledDates: { to: subDays(new Date(), 1) },
        timeOptions: [
          '09:00', '10:00', '11:00', '13:00'
        ],
      }
    },

    computed: {
      selectedDateTime() {
        return `${format(new Date(this.date), 'yyyy/MM/dd')} ${this.time}`
      }
    },  

    methods: {
      prevStep() {
        this.$router.push({
          name: 'LiffReservationStaff'
        })
      },
      nextStep() {
        this.$router.push({
          name: 'LiffReservationConfirm'
        })
      }
    }
  }
</script>


<style lang="scss" scoped>
.liff-reservation-schedule {

   ::v-deep .vdp-datepicker__calendar {
    border: 1px solid #E5E5EA;
    border-radius: 10px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    padding: 10px;

    .cell {
      position: relative;
      &.today:not(.selected) {
        background: transparent;
        color: var(--s-primary);
        border: 1px solid var(--s-primary);
        border: none;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 37px;
          height: 37px;
          background: var(--s-primary);
          opacity: 0.3;
          border-radius: 50%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 37px;
          height: 37px;
          border: 1px solid var(--s-primary);
          border-radius: 50%;
        }
      }

      &:not(.blank):not(.disabled).day:hover,
      &.selected,
      &.selected:hover {
        background: transparent;
        color: #fff;
        border: none;
        z-index: 0;

        &::before {
          content: '';
          position: absolute;
          background: var(--s-primary);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 37px;
          height: 37px;
          border: 1px solid var(--s-primary);
          border-radius: 50%;
          z-index: -1;
          box-shadow: 0 4px 4px 0 rgba(44, 44, 46, 0.15);
        }
      }
    }
  }

  &__notice {
    margin-top: 16px;

    &-divider {
      background: #E5E5EA;
      width: 100%;
      height: 1px;
      left: 0;
      position: absolute;
    }
  }

  &__timer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__time-input {
    display: none;
  }

  &__time-option {
    border: 1px solid #E5E5EA;
    width: calc((100% - 16px) / 3);
    text-align: center;
    font-weight: 500;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    height: 36px;
    line-height: 32px;
    &.selected {
      border: 3px solid var(--s-primary);
    }
  }

  &__confirm {
    position: fixed;
    max-width: 768px;
    margin: auto;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 13px;
  }
}

</style>